<template>
  <div>
    <div class="card-marketing d-flex flex-wrap justify-content-between" :class="colorCard">
      <b-col class="text-white d-flex flex-column justify-content-center">
    
        <div class="mt-1">
          <span class="text-information">{{ textInfo }}</span>
        </div>
        <div>
        <div class="mt-1">
          <span>{{ subtitle }}</span> 
        </div>
          <b-button variant="light" class="my-1"  :disabled="disabled_btn" @click="btn_action">{{textButton}}</b-button>
        </div>
      </b-col>
      <b-col class="position-relative">
        <div class="images-inner-first">
          <b-img :src="img" class="img-marketing" />
        </div>
      </b-col>
    </div>
  </div>
</template>
    
<script>
import { BButton, BCol, BImg, } from "bootstrap-vue";

export default {
  name: 'CardCastingCall',
  components: {
    BButton,
    BCol,
    BImg,
  },
  props: {
    disabled_btn: {
      type: Boolean,
      default: true,
    },
    can_create_casting: {
      type: Boolean,
      default: false,
    },
    btn_action: {
      type: Function,
      default: null
    },
    textInfo: {
      type: String,
      default:
        "Choose your champion. Get creators to pitch you.",
    },
    textButton: {
      type: String,
      default: "Create a casting call",
    },
    colorCard: {
      type: String,
      default: "color-card",
    },
    subtitle: {
      type: String,
      default: "Direct relationships without transaction fees.",
    },
    img: {
      type: String,
      default: '',
    },
    
  },
};
</script>
<style scoped>
.color-card {
    background: linear-gradient(to left bottom, #5A238D, #AA1192);
}
.text-information {
  font-size: calc(1.34375rem + 1.125vw);
  font-weight: bold;
  line-height: 1.2;
}
.card-marketing {
  width: 100%;
  min-height: 395px;
  border-radius: 1.5em;
  margin: 2em 2em 0 0;
  display: flex;
  overflow: hidden;
  padding: 1.5em;
}
.img-marketing {
  width: 490px;
  height: 330px;
  object-fit:contain;
  margin-bottom: 1em;
  border-radius: 5%;
}
.images-inner-first {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}
.images-inner-secondary {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -2em;
}
.opacity-25 {
  opacity: 0.25;
}
</style>